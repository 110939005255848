import React, { useEffect, useState } from "react"
import { Currency, AccommodationModel as AccommodationCluster, MealPlan, AccommodationFare } from "../../model"
import { cloneDeep } from "lodash"
import { calculateHighlightedAmenities, mapAccommodationFareToAnotherCurrency } from "../../utils/helpers"
import AccommodationClusterDesktop from "../AccommodationClusterDesktop"
import AccommodationClusterWeb from "../AccommodationClusterWeb"
import { useTranslation } from "react-i18next"
import { I18N_NS } from "../../utils/constants"

export type Props = {
  cluster: AccommodationCluster
  variant: "DESKTOP" | "WEB"
  nightsCount: number
  guestsCount: number
  roomsCount: number
  providerCurrenciesMap: Record<string, string[]>
  onViewHotel: () => void
  onAddToFavorite: (fav: boolean) => void
  isMobile?: boolean
  favorite?: boolean
  displayNetRateProvider?: boolean
  onAddToQuote?: () => void
  getExchangeRateRatio?: (currency: string, newCurrency: string) => Promise<number>
}

const AccommodationClusterRecommendedCarouselView: React.FC<Props> = ({
  cluster,
  onViewHotel,
  onAddToFavorite,
  onAddToQuote,
  getExchangeRateRatio,
  isMobile = false,
  nightsCount,
  guestsCount,
  roomsCount,
  favorite = false,
  variant,
  providerCurrenciesMap,
  displayNetRateProvider = false
}) => {
  const { t } = useTranslation(I18N_NS)

  const [loadingFare, setLoadingFare] = useState(false)

  const [loadingProvidersFare, setLoadingProvidersFare] = useState(false)

  const [currencySwitcher, setCurrencySwitcher] = useState<Currency>()

  const [selectedCurrencyFare, setSelectedCurrencyFare] = useState<AccommodationFare>()

  useEffect(() => {
    if (cluster.rates?.length) {
      handleOnSelectCurrency(cluster.rates[0].currency)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleOnSelectCurrency = async (newCurrency: string) => {
    if (!getExchangeRateRatio) {
      return
    }

    if (selectedCurrencyFare?.currency === newCurrency) {
      return
    }

    try {
      setLoadingFare(true)

      setLoadingProvidersFare(cluster.rates.length > 1)

      const fare = cluster.rates[0].fare!

      const newRatio = await getExchangeRateRatio(fare.currency, newCurrency)

      const newFare = cloneDeep(fare)

      mapAccommodationFareToAnotherCurrency(newFare, newCurrency, newRatio)

      setSelectedCurrencyFare(newFare)

      setCurrencySwitcher({
        currency: newCurrency,
        originalCurrency: {
          currency_code: fare.currency,
          ratio: newRatio
        },
        enabledSwitch: false
      })
    } catch (error) {
      console.error(error)
    } finally {
      setLoadingFare(false)
      setLoadingProvidersFare(false)
    }
  }

  const { name, address, rates, images, amenities, stars, category } = cluster

  if (!rates?.length) {
    return <></>
  }

  const otherProviders = rates.map(r => ({
    provider: r.provider,
    fare: r.fare!,
    promotions: !!r.promotions
  }))

  const selectedRate = rates[0]

  const { fare, provider, meal_plan: mealPlan, refundable, promotions } = selectedRate

  const photo = images && images.length > 0 ? images[0].url : null

  const { wifi, parking, pool, receptionAllDay } = amenities
    ? calculateHighlightedAmenities(amenities)
    : { wifi: false, pool: false, parking: false, receptionAllDay: false }

  const currencies = providerCurrenciesMap[provider]

  const selectedFare = selectedCurrencyFare || fare!

  const selectedCurrency = selectedCurrencyFare?.currency || fare!.currency

  if (variant === "DESKTOP") {
    return (
      <AccommodationClusterDesktop
        name={name}
        address={address}
        stars={stars}
        nightsCount={nightsCount}
        guestsCount={guestsCount}
        roomsCount={roomsCount}
        provider={provider}
        mealPlan={mealPlan as MealPlan}
        photo={photo}
        freeCancel={refundable}
        currencies={currencies}
        selectedCurrency={selectedCurrency}
        currencySwitcher={currencySwitcher}
        promotions={promotions}
        accommodationFare={selectedFare}
        onViewHotel={onViewHotel}
        onAddToFavorite={onAddToFavorite}
        onAddToQuote={onAddToQuote}
        onSelectCurrency={handleOnSelectCurrency}
        otherProviders={otherProviders || []}
        favorite={favorite}
        loadingFare={loadingFare}
        loadingProviderPrice={loadingProvidersFare}
        wifi={wifi}
        pool={pool}
        parking={parking}
        receptionAllDay={receptionAllDay}
        displayNetRateProvider={displayNetRateProvider}
      />
    )
  }

  const taxesDisclaimers = []

  taxesDisclaimers.push(t("AccommodationClusterRecommended.taxes_included"))

  const paisTax = selectedRate.fees ? selectedRate.fees.find((f: any) => f.type === "PAIS") : undefined
  if (paisTax) taxesDisclaimers.push(t("AccommodationClusterRecommended.pais_tax"))

  const rg4815Tax = selectedRate.fees ? selectedRate.fees.find((f: any) => f.type === "RG4815/20") : undefined
  if (rg4815Tax) taxesDisclaimers.push(t("AccommodationClusterRecommended.rg4815_tax"))

  const rg5272Tax = selectedRate.fees ? selectedRate.fees.find((f: any) => f.type === "RG5272/22") : undefined
  if (rg5272Tax) taxesDisclaimers.push(t("AccommodationClusterRecommended.rg5272_tax"))

  return (
    <AccommodationClusterWeb
      name={name}
      address={address}
      stars={stars}
      mealPlan={mealPlan as MealPlan}
      photo={photo}
      freeCancel={refundable}
      taxesDisclaimers={taxesDisclaimers}
      accommodationFare={fare!}
      onViewHotel={onViewHotel}
      guests={guestsCount}
      nights={nightsCount}
      isMobile={isMobile}
      wifi={wifi}
      pool={pool}
      parking={parking}
      receptionAllDay={receptionAllDay}
      category={category}
    />
  )
}

export default AccommodationClusterRecommendedCarouselView
