import React from "react"
import styles from "./AccommodationClusterRecommendedCarouselPagination.styles"
import { useTheme } from "@basset-la/themed-components"

export type Props = {
  selectedIndex: number
  counts: number
  onSelectIndex: (index: number) => void
}

const AccommodationClusterRecommendedCarouselPagination: React.FC<Props> = ({
  counts,
  selectedIndex,
  onSelectIndex
}) => {
  const theme = useTheme()

  const handleOnChangeIndex = (index: number) => {
    onSelectIndex(index)
  }

  const handleOnClick = (index: number) => {
    return () => handleOnChangeIndex(index)
  }

  const dots = () => {
    return Array.from({ length: counts }, (_, index) => (
      <div key={index} className={styles.dot(theme, selectedIndex === index)} onClick={handleOnClick(index)} />
    ))
  }

  return <div className={styles.root}>{dots()}</div>
}

export default AccommodationClusterRecommendedCarouselPagination
