import React from "react"
import Alert from "@material-ui/lab/Alert"
import Card from "@basset-la/components-commons/dist/components/Card"
import Text from "@basset-la/components-commons/dist/components/Text"
import { CancelPenalty } from "../../model"
import { useTranslation } from "react-i18next"
import { I18N_NS } from "../../utils/constants"
import { formatPrice, getLocale } from "@basset-la/components-products/dist/utils/helpers"
import moment from "moment"
import styles from "./CancelPenalties.styles"

export interface Props {
  cancelPenalties: CancelPenalty[]
  refundable: boolean
  expirationDate: string
  resellerExpirationDate?: string
  customExpirationDate?: string
  variant?: "NORMAL" | "ALERT"
}

const CancelPenalties: React.FC<Props> = ({
  cancelPenalties,
  refundable,
  expirationDate,
  resellerExpirationDate,
  customExpirationDate,
  variant = "NORMAL"
}) => {
  const { t, i18n } = useTranslation(I18N_NS)
  const locale = getLocale(i18n)

  let policyText = ""

  if (!refundable) {
    policyText = t("CancelPenalties.not_refundable")
  } else {
    let d = expirationDate
    if (resellerExpirationDate) d = resellerExpirationDate
    if (customExpirationDate) d = customExpirationDate

    const expDate = moment(d)
    const refDate = moment(d).subtract(1, "minute")

    if (variant === "ALERT") {
      policyText = t("CancelPenalties.refundable", {
        date: refDate.format("DD/MM/YYYY"),
        time: refDate.format("HH:mm A")
      })
    } else {
      policyText += t("CancelPenalties.refundable_1", {
        date: refDate.format("DD/MM/YYYY"),
        time: refDate.format("HH:mm A")
      })

      cancelPenalties.forEach((p, idx) => {
        const start = idx === 0 ? expDate : moment(p.start)
        const end = moment(p.end)
        const textIdx = idx < cancelPenalties.length - 1 ? "2" : "3"

        if (p.nights && p.nights > 0) {
          policyText += t(`CancelPenalties.refundable_${textIdx}_night`, {
            count: p.nights,
            start_date: start.format("DD/MM/YYYY"),
            start_time: start.format("HH:mm A"),
            end_date: end.format("DD/MM/YYYY"),
            end_time: end.format("HH:mm A")
          })
        } else {
          if (p.amount && p.amount > 0) {
            policyText += t(`CancelPenalties.refundable_${textIdx}_price`, {
              price: `${formatPrice(p.amount, locale, p.currency_code)}`,
              start_date: start.format("DD/MM/YYYY"),
              start_time: start.format("HH:mm A"),
              end_date: end.format("DD/MM/YYYY"),
              end_time: end.format("HH:mm A")
            })
          } else {
            if (p.percentage) {
              policyText += t(`CancelPenalties.refundable_${textIdx}_percentage`, {
                percentage: p.percentage,
                start_date: start.format("DD/MM/YYYY"),
                start_time: start.format("HH:mm A"),
                end_date: end.format("DD/MM/YYYY"),
                end_time: end.format("HH:mm A")
              })
            }
          }
        }
      })

      policyText += t("CancelPenalties.refundable_4")
    }
  }

  return (
    <>
      {variant === "NORMAL" && (
        <Card variant="outlined">
          <div className={styles.content}>
            <Text className={styles.title} variant={"bold"} size={16}>
              {t("CancelPenalties.title")}
            </Text>
            <Text size={14}>{policyText}</Text>
          </div>
        </Card>
      )}
      {variant === "ALERT" && <Alert severity={refundable ? "success" : "warning"}>{policyText}</Alert>}
    </>
  )
}

export default CancelPenalties
