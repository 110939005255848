import { Theme } from "@basset-la/themed-components"
import { css } from "@emotion/css"

export default {
  root: css({
    display: "flex",
    gap: "8px"
  }),
  dot: (theme: Theme, active: boolean) =>
    css({
      height: "8px",
      width: "8px",
      borderRadius: 6,
      backgroundColor: active ? theme.colors.text_white : "transparent",
      border: `1px solid ${theme.colors.text_white}`
    })
}
