import React from "react"
import styles from "./CheckinInstructions.styles"
import Card from "@basset-la/components-commons/dist/components/Card"
import Text from "@basset-la/components-commons/dist/components/Text"
import { useTranslation } from "react-i18next"
import { I18N_NS } from "../../utils/constants"

export interface Props {
  description: string
}

const CheckinInstructions: React.FC<Props> = ({ description }) => {
  const { t } = useTranslation(I18N_NS)

  return (
    <Card variant="outlined">
      <div className={styles.content}>
        <Text className={styles.title} variant={"bold"} size={16}>
          {t("CheckinInstructions.title")}
        </Text>
        <p dangerouslySetInnerHTML={{ __html: description }} className={styles.description} />
      </div>
    </Card>
  )
}

export default CheckinInstructions
