import React from "react"
import styles from "./AccommodationClusterRecommendedCarousel.styles"
import SwipeableViews from "react-swipeable-views"
import LeftArrow from "@material-ui/icons/KeyboardArrowLeftRounded"
import RightArrow from "@material-ui/icons/KeyboardArrowRightRounded"
import IconButton from "@basset-la/component-material-ui-wrapper/dist/IconButton"
import AccommodationClusterRecommendedCarouselPagination from "./AccommodationClusterRecommendedCarouselPagination"
import { useTheme } from "@basset-la/themed-components"
import { AccommodationModel as AccommodationCluster } from "../../model"
import AccommodationClusterRecommendedCarouselView from "./AccommodationClusterRecommendedCarouselView"

export type Props = {
  clusters: AccommodationCluster[]
  selectedIndex: number
  variant: "DESKTOP" | "WEB"
  isMobile: boolean
  nightsCount: number
  guestsCount: number
  roomsCount: number
  displayNetRateProvider?: boolean
  favoriteIds?: Set<string>
  providerCurrenciesMap?: Record<string, string[]>
  onSelectIndex: (index: number) => void
  onViewHotel: (index: number) => void
  onAddToFavorite: (index: number, fav: boolean) => void
  onAddToQuote?: (index: number) => void
  getExchangeRateRatio?: (currency: string, newCurrency: string) => Promise<number>
}

const AccommodationClusterRecommendedCarousel: React.FC<Props> = ({
  clusters,
  selectedIndex,
  onSelectIndex,
  onViewHotel,
  onAddToFavorite,
  onAddToQuote,
  variant,
  nightsCount,
  guestsCount,
  roomsCount,
  favoriteIds,
  providerCurrenciesMap = {},
  getExchangeRateRatio,
  isMobile,
  displayNetRateProvider
}) => {
  const theme = useTheme()

  const onPrevCluster = () => {
    onSelectIndex(selectedIndex - 1)
  }

  const onNextCluster = () => {
    onSelectIndex(selectedIndex + 1)
  }

  const handleOnChangeIndex = (index: number) => {
    onSelectIndex(index)
  }

  const handleOnViewHotel = (index: number) => {
    return () => onViewHotel(index)
  }

  const handleOnAddToFavorite = (index: number) => {
    return (fav: boolean) => onAddToFavorite(index, fav)
  }

  const handleOnAddToQuote = (index: number) => {
    return () => onAddToQuote && onAddToQuote(index)
  }

  const isFirstItem = selectedIndex === 0

  const isLastItem = selectedIndex === clusters.length - 1

  return (
    <div className={styles.root}>
      <SwipeableViews onChangeIndex={handleOnChangeIndex} index={selectedIndex} enableMouseEvents>
        {clusters.map((cluster, i) => (
          <AccommodationClusterRecommendedCarouselView
            key={cluster.id}
            cluster={cluster}
            nightsCount={nightsCount}
            guestsCount={guestsCount}
            roomsCount={roomsCount}
            providerCurrenciesMap={providerCurrenciesMap}
            onViewHotel={handleOnViewHotel(i)}
            onAddToFavorite={handleOnAddToFavorite(i)}
            favorite={favoriteIds?.has(cluster.id)}
            getExchangeRateRatio={getExchangeRateRatio}
            isMobile={isMobile}
            onAddToQuote={handleOnAddToQuote(i)}
            variant={variant}
            displayNetRateProvider={displayNetRateProvider}
          />
        ))}
      </SwipeableViews>
      <div className={styles.buttonContainer}>
        <IconButton disabled={isFirstItem} onClick={onPrevCluster} borderColor="none">
          <LeftArrow className={styles.icon(theme, isFirstItem)} />
        </IconButton>
        <AccommodationClusterRecommendedCarouselPagination
          selectedIndex={selectedIndex}
          counts={clusters.length}
          onSelectIndex={handleOnChangeIndex}
        />
        <IconButton disabled={isLastItem} onClick={onNextCluster} borderColor="none">
          <RightArrow className={styles.icon(theme, isLastItem)} />
        </IconButton>
      </div>
    </div>
  )
}

export default AccommodationClusterRecommendedCarousel
