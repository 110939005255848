import { Theme } from "@basset-la/themed-components"
import { css } from "@emotion/css"

export default {
  root: css({
    display: "flex",
    flexDirection: "column"
  }),
  buttonContainer: css({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "8px",
    marginTop: "8px"
  }),
  icon: (theme: Theme, disable: boolean) =>
    css({
      "&.MuiSvgIcon-root": {
        color: theme.colors.text_white,
        opacity: disable ? "0.3" : "1.0",
        width: "16px",
        height: "16px"
      }
    })
}
