import React, { useState } from "react"
import { useTheme } from "@basset-la/themed-components"
import styles from "./AccommodationClusterRecommended.styles"
import Text from "@basset-la/components-commons/dist/components/Text"
import TagIcon from "../../icons/TagIcon"
import AccommodationClusterRecommendedCarousel from "./AccommodationClusterRecommendedCarousel"
import { AccommodationModel as AccommodationCluster } from "../../model"

export type Props = {
  clusters: AccommodationCluster[]
  nightsCount: number
  guestsCount: number
  roomsCount: number
  onViewHotel: (index: number) => void
  onAddToFavorite: (index: number, fav: boolean) => void
  title?: string
  variant?: "DESKTOP" | "WEB"
  isMobile?: boolean
  favoriteIds?: Set<string>
  providerCurrenciesMap?: Record<string, string[]>
  displayNetRateProvider?: boolean
  onAddToQuote?: (index: number) => void
  getExchangeRateRatio?: (currency: string, newCurrency: string) => Promise<number>
}

const AccommodationClusterRecommended: React.FC<Props> = ({
  title,
  clusters,
  onViewHotel,
  onAddToFavorite,
  onAddToQuote,
  getExchangeRateRatio,
  isMobile = false,
  variant = "DESKTOP",
  nightsCount,
  guestsCount,
  roomsCount,
  favoriteIds,
  providerCurrenciesMap,
  displayNetRateProvider
}) => {
  const theme = useTheme()

  const [selectedIndex, setSelectedIndex] = useState(0)

  const handleOnSelectIndex = (index: number) => {
    setSelectedIndex(index)
  }

  return (
    <div className={styles.root(theme)}>
      {!!title && (
        <div className={styles.titleContainer}>
          <TagIcon color={theme.colors.text_white} />
          <Text size={18} variant="bold" color="white">
            {title}
          </Text>
        </div>
      )}
      <AccommodationClusterRecommendedCarousel
        clusters={clusters}
        onViewHotel={onViewHotel}
        onAddToFavorite={onAddToFavorite}
        onSelectIndex={handleOnSelectIndex}
        selectedIndex={selectedIndex}
        onAddToQuote={onAddToQuote}
        variant={variant}
        isMobile={isMobile}
        nightsCount={nightsCount}
        guestsCount={guestsCount}
        roomsCount={roomsCount}
        favoriteIds={favoriteIds}
        getExchangeRateRatio={getExchangeRateRatio}
        providerCurrenciesMap={providerCurrenciesMap}
        displayNetRateProvider={displayNetRateProvider}
      />
    </div>
  )
}

export default AccommodationClusterRecommended
