import { Theme } from "@basset-la/themed-components"
import { css } from "@emotion/css"

export default {
  root: (theme: Theme) =>
    css({
      display: "flex",
      flexDirection: "column",
      fontFamily: "Roboto",
      color: theme.colors.text_white,
      backgroundColor: theme.colors.brand_primary,
      width: "100%",
      overflow: "hidden",
      position: "relative",
      boxSizing: "border-box",
      borderRadius: "16px",
      transition: "box-shadow .3s ease",
      boxShadow: "0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12)",
      ":hover": {
        boxShadow: "0 4px 4px 0 rgba(0, 0, 0, 0.24), 0 0 4px 0 rgba(0, 0, 0, 0.12)"
      },
      padding: "16px 8px"
    }),
  titleContainer: css({
    display: "flex",
    alignItems: "center",
    gap: "8px",
    margin: "0px 0px 16px 8px"
  })
}
