import { css } from "@emotion/css"

export default {
  content: css({
    display: "flex",
    flexDirection: "column"
  }),
  title: css({
    marginBottom: "8px !important"
  }),
  description: css({
    fontSize: "14px",
    fontWeight: 300,
    lineHeight: "24px",
    margin: 0,
    textAlign: "justify",
    "> *": {
      margin: 0,
      padding: 0
    }
  })
}
